
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

import Lenis from 'lenis'

const lenis = new Lenis({
	duration: 1.45,
	direction: 'vertical',
	gestureDirection: 'vertical',
	smooth: true,
	mouseMultiplier: 1,
	smoothTouch: false,
	touchMultiplier: 2,
	infinite: false,
});

function raf(time) {
	lenis.raf(time);
	requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

let swiperDeveloper = null;

function initializeSwiper() {
	if (window.innerWidth <= 992 && !swiperDeveloper) {
		swiperDeveloper = new Swiper('.section-developer__elements', {
			loop: true,
			slidesPerView: "auto",
			spaceBetween: 10,
			centeredSlides: true,
			watchSlidesProgress: true,
			watchSlidesVisibility: true,
		});

		swiperDeveloper.update();

	} else if (window.innerWidth > 992 && swiperDeveloper) {
		swiperDeveloper.destroy(true, true);
		swiperDeveloper = null;
	}
}

document.addEventListener('DOMContentLoaded', function () {
	initializeSwiper();
});

window.addEventListener('resize', () => {
	initializeSwiper();
});


document.addEventListener('DOMContentLoaded', function () {
	const swiperGallery = new Swiper('.section-slider__elements', {
		modules: [Navigation],
		loop: true,
		slidesPerView: "auto",
		spaceBetween: 10,
		watchSlidesProgress: true,
		watchSlidesVisibility: true,
		navigation: {
			nextEl: '.section-slider .swiper-button-next',
			prevEl: '.section-slider .swiper-button-prev',
		},
		centeredSlides: true,
		breakpoints: {
			991: {
				centeredSlides: false,
			}
		}
	});
});

window.onload = function () {
	window.scrollTo(0, 0);
};

window.addEventListener('load', function () {
	setTimeout(function () {
		document.body.classList.add('loaded');
	}, 250);

	setTimeout(function () {
		document.body.classList.add('start');
	}, 500);
});

document.addEventListener('DOMContentLoaded', function () {
	const main = document.querySelector(".section-main");
	let mainTimeline;

	if (main) {
		mainTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: main,
				start: "top top",
				end: "bottom top",
				scrub: true,
				pin: true,
				pinSpacing: true,
				//markers: true,
			}
		});

		mainTimeline
			.to(".section-main__image", {
				width: "100%",
				height: "100%",
				duration: 1.2,
				ease: "none"
			})

			.to(".flatta", {
				top: '100%',
				duration: 1,
				ease: "none"
			}, 0)

			.to(".section-main .section-main__text", {
				opacity: 0,
				duration: 0.5,
				ease: "none"
			}, 0);
	}

	const architect = document.querySelector(".section-architect__full-height");
	let architectTimeline;

	let scrollTriggerConfig = {
		trigger: architect,
		start: "top top",
		end: "bottom top",
		scrub: true,
		pin: true,
		pinSpacing: true,
		//markers: true,
	};

	// Użycie matchMedia do zmiany tylko start i end dla mniejszych rozdzielczości
	gsap.matchMedia().add("(max-width: 768px)", () => {
		scrollTriggerConfig.start = "top bottom"; // Zmiana wartości dla mniejszych ekranów
		scrollTriggerConfig.end = "bottom bottom";
		scrollTriggerConfig.pin = false;
	});

	if (architect) {
		architectTimeline = gsap.timeline({
			scrollTrigger: scrollTriggerConfig
		});

		architectTimeline.to(".section-architect__full-height img", {
			width: "100%",
			height: "100%",
			duration: 1,
			ease: "none"
		});

	}



	const advantages = document.querySelector(".section-advantages");
	let advantagesTimeline;

	const isLoggedIn = document.body.classList.contains('logged-in');

	if (advantages) {
		advantagesTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: advantages,
				start: "top top",
				end: "bottom bottom",
				scrub: true,
				pin: ".section-advantages__thumbnails",
				pinSpacing: true,
				//markers: isLoggedIn,
			}
		});
	}

	const sectionNumbers = document.querySelector(".numbers");

	if (sectionNumbers) {
		const numberTimeline0 = gsap.timeline({
			scrollTrigger: {
				trigger: sectionNumbers, // Główny kontener z sekcjami nakładającymi się
				start: "top center",
				end: "bottom center",
				scrub: true, // Płynne przewijanie
				pin: false, // Przypnij kontener podczas przewijania
				pinSpacing: false,
				//markers: true,
			}
		});



		const numbers = document.querySelectorAll(".numbers__single");

		numbers.forEach((number, index) => {
			const numberTimeline = gsap.timeline({
				scrollTrigger: {
					trigger: number, // Każdy element jest osobnym triggerem
					start: "top 75%", // Animacja zaczyna się, gdy górna część elementu wejdzie w centrum widoku
					end: "bottom 50%", // Kończy się, gdy dolna część elementu osiągnie centrum widoku
					scrub: true, // Płynne przewijanie
					pin: false,
					pinSpacing: false,
					//	once: true,
					//markers: true, // Widoczne markery dla debugowania
				}
			})



			const h1 = number.querySelector('.numbers__number');
			const isWhite = h1.classList.contains('numbers__number--white');
			const targetColor = isWhite ? "rgba(255, 255, 255, 1)" : "rgba(54, 56, 63, 1)";

			numberTimeline.to(h1, {
				color: targetColor,
				duration: 1
			}, 0);

			const description = number.querySelector('.numbers__description');
			numberTimeline.to(description, {
				opacity: 1,
				duration: 1,
			}, 0);



		});
	}


	const elements = document.querySelectorAll(".gsap-element");

	if (elements) {
		elements.forEach((element) => {

			const delay = element.getAttribute('data-delay') === 'true' ? 0.05 : 0;


			gsap.timeline({
				scrollTrigger: {
					trigger: element, // Każdy element jest osobnym triggerem
					start: "top 80%", // Animacja zaczyna się, gdy górna część elementu wejdzie w centrum widoku
					end: "bottom center", // Kończy się, gdy dolna część elementu osiągnie centrum widoku
					scrub: false, // Płynne przewijanie
					//markers: true, // Widoczne markery dla debugowania
					//once: true,
				},
			}).fromTo(
				element,
				{ y: 100, opacity: 0 }, // Start animacji
				{ y: 0, opacity: 1, duration: 0.7, delay: delay } // Koniec animacji
			);
		});
	}


	const envelopes = document.querySelectorAll('.envelope');

	envelopes.forEach(envelope => {
		envelope.addEventListener('click', () => {
			document.body.classList.add('is-overlay');
			lenis.stop();
		});
	});

	document.getElementById('close-form').addEventListener('click', () => {
		document.body.classList.remove('is-overlay');
		lenis.start();
	});


	document.getElementById('scroll-down').addEventListener('click', () => {
		const section2 = document.getElementById('section-2');
		lenis.scrollTo(section2, { offset: 0, duration: 1.2 });
	});

	document.getElementById('scroll-developer').addEventListener('click', () => {
		const sectionDeveloper = document.getElementById('deweloper');
		lenis.scrollTo(sectionDeveloper, { offset: 0, duration: 2.1 });
	});




	const thumbnails = document.querySelectorAll(".section-advantages .advantages"); // Wszystkie zdjęcia w thumbnails
	const section = document.querySelector(".section-advantages"); // Sekcja, w której znajdują się zdjęcia
	const targetElements = document.querySelectorAll(".target-element"); // Elementy, do których przypisujemy klasę na podstawie data-id

	// Funkcja sprawdzająca, które zdjęcie znajduje się na środku
	function updateActiveThumbnail() {
		const sectionBounds = section.getBoundingClientRect(); // Pozycja sekcji względem widoku
		const middleOfViewport = window.innerHeight / 2; // Środek widoku
		let closestThumbnail = null;
		let closestDistance = Infinity; // Zmienna do śledzenia najbliższego zdjęcia

		thumbnails.forEach((thumbnail) => {
			const thumbnailBounds = thumbnail.getBoundingClientRect(); // Pozycja zdjęcia względem widoku
			const thumbnailMiddle = (thumbnailBounds.top + thumbnailBounds.bottom) / 2; // Środek zdjęcia

			// Obliczanie odległości od środka ekranu
			const distance = Math.abs(thumbnailMiddle - middleOfViewport);

			// Jeśli odległość zdjęcia od środka jest mniejsza niż dotychczasowa
			if (distance < closestDistance) {
				closestThumbnail = thumbnail;
				closestDistance = distance;
			}
		});

		// Pobranie data-id najbliższego zdjęcia
		let activeDataId = null;
		if (closestThumbnail) {
			activeDataId = closestThumbnail.getAttribute("data-id");
		}

		// Usunięcie klasy "active" ze wszystkich elementów targetowych
		targetElements.forEach((target) => target.classList.remove("active"));

		// Przypisanie klasy "active" na podstawie data-id
		if (activeDataId) {
			const target = document.querySelector(`.target-element[data-id="${activeDataId}"]`);
			if (target) {
				target.classList.add("active");
			}
		}
	}

	// Nasłuchiwanie przewijania
	window.addEventListener("scroll", updateActiveThumbnail);

	// Wywołanie funkcji na początku (np. po załadowaniu strony)
	updateActiveThumbnail();

	targetElements.forEach(element => {
		element.addEventListener('click', function () {
			const id = this.getAttribute("data-id");
			const advantagesSingle = document.getElementById('advantages-' + id);

			if (advantagesSingle) {
				lenis.scrollTo(advantagesSingle, { offset: 0, duration: 1.2 });
			}
		});
	});

	const forms = document.querySelectorAll('.wpcf7-form');

	forms.forEach(form => {
		// Pobieramy checkbox "check-all" w danym formularzu
		const checkAll = form.querySelector('.check-all');
		// Pobieramy pozostałe checkboxy w danym formularzu
		const individualCheckboxes = form.querySelectorAll('.individual-checkbox');

		if (checkAll && individualCheckboxes.length > 0) {
			// Zaznacz/odznacz wszystkie
			checkAll.addEventListener('change', function () {
				individualCheckboxes.forEach(checkbox => {
					checkbox.checked = checkAll.checked;
				});
				console.log('checked');
			});

			// Odznaczenie "Zaznacz wszystkie", jeśli któryś z pozostałych checkboxów jest odznaczony
			individualCheckboxes.forEach(checkbox => {
				checkbox.addEventListener('change', function () {
					if (!checkbox.checked) {
						checkAll.checked = false;
					} else {
						// Sprawdzenie, czy wszystkie są zaznaczone
						const allChecked = Array.from(individualCheckboxes).every(cb => cb.checked);
						checkAll.checked = allChecked;
					}
				});
			});
		}
	});

	const buttons = document.querySelectorAll(".more");

	buttons.forEach((button) => {
		button.addEventListener("click", () => {
			const span = button.previousElementSibling;
			const isHidden = span.style.display === "none" || span.style.display === ""
			span.style.display = isHidden ? "inline" : "none";
			button.textContent = isHidden ? "Pokaż mniej" : "Czytaj więcej";
		});
	});

	const inputs = document.querySelectorAll('.wpcf7-text, .wpcf7-tel, .wpcf7-email, textarea');

	inputs.forEach(input => {
		input.addEventListener('focus', () => {
			input.dataset.placeholder = input.placeholder;
			input.placeholder = '';
		});

		input.addEventListener('blur', () => {
			input.placeholder = input.dataset.placeholder;
		});
	});

	const menuButtonOpen = document.getElementById('menu-open');
	const menuButtonClose = document.getElementById('menu-close');

	menuButtonOpen.addEventListener("click", () => {
		document.body.classList.add('is-menu-open');
	});

	menuButtonClose.addEventListener("click", () => {
		document.body.classList.remove('is-menu-open');
	})

	const menuLinks = document.querySelectorAll('.nav button');

	menuLinks.forEach(button => {
		button.addEventListener('click', () => {

			const sectionId = button.dataset.id;
			const sectionElement = document.getElementById(sectionId);
			lenis.scrollTo(sectionElement, { offset: 0, duration: 0.5 });
			setTimeout(() => {
				document.body.classList.remove('is-menu-open');
			}, 500);
		});
	});

});